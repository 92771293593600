import { useLocation } from 'react-router-dom';
import React from 'react';

function FlashMessage() {

  const location = useLocation();
  const { flash } = location.state || { flash: { } };

  if (!location.state || !location.state.flash) {
    return null;
  }

  return (
    <div className="container flash-message-container">
      {
        flash.error ?
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>{ flash.error.title }</strong><br /><span>{ flash.error.body }</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : null
      }
      {
        flash.success ?
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{ flash.success.title }</strong><br /><span>{ flash.success.body }</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : null
      }
      {
        flash.info ?
          <div className="alert alert-primary alert-dismissible fade show" role="alert">
            <strong>{ flash.info.title }</strong><br /><span>{ flash.info.body }</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : null
      }
    </div>
  );
}

export default FlashMessage;
