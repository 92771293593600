import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import GuestInfoPage from './GuestPages/GuestInfoPage';
import GuestListPage from './GuestPages/GuestlistPage';
import ItemListPage from './ItemPages/ItemListPage';
import ItemCategoryListPage from './ItemCategoryListPage/ItemCategoryListPage';
import DepositCategoryListPage from './DepositCategoryListPage/DepositCategoryListPage';
import styles from './AppAuthenticated.module.scss';
import NewGuestPage from './GuestPages/NewGuestPage';
import NewItemPage from './ItemPages/NewItemPage';
import NewCategoryPage from './NewCategoryPage/NewCategoryPage';
import NewDepositPage from './NewDepositPage/NewDepositPage';
import TopUpPage from './TopUpPage';
import DeleteItemPage from './ItemPages/DeleteItemPage';
import EditItemPage from './ItemPages/EditItemPage';
import TransactionDetailPage from './TransactionDetailPage';
import GuestEditPage from './GuestPages/GuestEditPage';
import NewGuestTransactionPage from './GuestPages/NewGuestTransactionPage';
import AppHomePage from './AppHome/AppHomePage';
import TerminalListPage from './TerminalPages/TerminalListPage';
import NewTerminalPage from './TerminalPages/NewTerminalPage';
import TerminalDetailsPage from './TerminalPages/TerminalDetailsPage';
import FlashMessage from './FlashMessage';
import ProcessingOverlay from './ProcessingOverlay/ProcessingOverlay';

const ImportUserPage = React.lazy(() => import('./ImportUserPage/ImportUserPage'));

function AppAuthenticated({ axios, setJWT }) {

  const [ instanceContext, setInstanceContext ] = useState(JSON.parse(localStorage.getItem('CLE_INSTANCE_CONTEXT')));

  const fetchInstanceContext  = async () => {
    try {
      const instanceContext = await axios.get('/instance/context');
      localStorage.setItem('CLE_INSTANCE_CONTEXT', JSON.stringify(instanceContext.data));
      setInstanceContext(instanceContext.data);
    } catch (e) {

    }
  }

  useEffect(() => {
    fetchInstanceContext();
  }, []);

  const eventName = instanceContext ? instanceContext.eventData.name : 'CLE WEB';

  return (
    <div className={styles['appAuthenticated']}>
      <Navbar setJWT={setJWT} eventName={eventName} />
      <FlashMessage />
      <Switch>
        <Route path='/guests/import'>
          <Suspense fallback={<ProcessingOverlay text="Lade Import Tool" />}>
            <ImportUserPage axios={axios} eventName={eventName} />
          </Suspense>
        </Route>
        <Route path='/transactions/:transactionId'><TransactionDetailPage axios={axios} eventName={eventName} /></Route>
        <Route path='/top-up/:username'><TopUpPage axios={axios} eventName={eventName} topUpItem={instanceContext ? instanceContext.topUpItems[0] : {}} /></Route>
        <Route path='/guests/new'><NewGuestPage axios={axios} eventName={eventName} /></Route>
        <Route path='/guests/:username/edit'><GuestEditPage axios={axios} eventName={eventName} /></Route>
        <Route path='/guests/:username/newTransaction'><NewGuestTransactionPage axios={axios} eventName={eventName} /></Route>
        <Route path='/guests/:username'><GuestInfoPage axios={axios} eventName={eventName} /></Route>
        <Route path='/guests'><GuestListPage axios={axios} eventName={eventName} /></Route>
        <Route path='/terminals/new'><NewTerminalPage axios={axios} eventName={eventName} /></Route>
        <Route path='/terminals/:tid'><TerminalDetailsPage axios={axios} eventName={eventName} /></Route>
        <Route path='/terminals'><TerminalListPage axios={axios} eventName={eventName} /></Route>
        <Route path='/items/new'><NewItemPage axios={axios} eventName={eventName} /></Route>
        <Route path='/items/categories/new'><NewCategoryPage axios={axios} eventName={eventName} /></Route>
        <Route path='/items/categories'><ItemCategoryListPage axios={axios} eventName={eventName} /></Route>
        <Route path='/items/:itemId/delete'><DeleteItemPage axios={axios} eventName={eventName} /></Route>
        <Route path='/items/:itemId/edit'><EditItemPage axios={axios} eventName={eventName} /></Route>
        <Route path='/items'><ItemListPage axios={axios} eventName={eventName} /></Route>
        <Route path='/deposits/new'><NewDepositPage axios={axios} eventName={eventName} /></Route>
        <Route path='/deposits'><DepositCategoryListPage axios={axios} eventName={eventName} /></Route>
        <Route path='/'><AppHomePage axios={axios} eventName={eventName} /></Route>
      </Switch>
    </div>
  );
}

export default AppAuthenticated;
