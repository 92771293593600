import React from 'react';

function Alert({ type = 'warning', text, children }) {
  return (
    <div className="container">
      <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
        {children}
        {text}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
}

export default Alert;
