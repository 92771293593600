import UniqueChipsCard from '../Cards/UniqueChipsCard';
import ScansCard from '../Cards/ScansCard';
import SumChargesCard from '../Cards/SumChargesCard';
import GrossAmountCard from '../Cards/GrossAmountCard';
import TopSellersCard from '../Cards/TopSellersCard';
import { Helmet } from 'react-helmet';
import ScanLocationsCard from '../Cards/ScanLocationsCard';

function AppHomePage({ axios, eventName }) {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Dashboard - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>DASHBOARD</h2>
      <div className="row mb-4">
        <div className="col-md-3">
          <UniqueChipsCard axios={axios} />
        </div>
        <div className="col-md-3">
          <ScansCard axios={axios} />
        </div>
        <div className="col-md-3">
          <SumChargesCard axios={axios} />
        </div>
        <div className="col-md-3">
          <GrossAmountCard axios={axios} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <TopSellersCard axios={axios} />
        </div>
        <div className="col-md-3">
          <ScanLocationsCard axios={axios} />
        </div>
      </div>
    </div>
  );
}

export default AppHomePage;
