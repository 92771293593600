import React from 'react';

function AppSetup() {
  return (
    <div>
      App Setup
    </div>
  );
}

export default AppSetup;
