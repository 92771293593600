import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function TopUpPage({ axios, topUpItem, eventName }) {
  const { username } = useParams();
  const history = useHistory();

  const [ value, setValue ] = useState('');
  const [ description, setDescription ] = useState('Guthabenaufladung über Webinterface');
  const [ error, setError ] = useState(null);

  const onBookClick = async () => {
    setError(null);

    try {
      const response = await axios.post(`/transactions?uname=${username}`, {
        items: [{
          amount: 1,
          item: {
            itemId: topUpItem._id,
            price: value * (-1),
            comment: description
          }
        }]
      });

      history.replace(`/guests/${username}`);
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.data.message) {
        setError(e.response.data.message);
      } else {
        setError(e.message);
      }
    }
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Guthabenaufladung - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>GUTHABEN AUFLADEN</h2>
      <h4 className="text-muted">{username.toUpperCase()}</h4>
      <div className="container">
        {
          error ?
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
              <strong>Transaktion fehlgeschlagen</strong><br /><span>{ error }</span>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> : null
        }
        <div className="card">
          <div className="card-body">
            <form>
              <div className="mb-3">
                <label htmlFor="valueInput" className="form-label">Betrag</label>
                <div className="input-group">
                  <input type="number" min="0" step="0.1" className="form-control" id="valueInput" name="value" aria-describedby="basic-addon2"
                         placeholder="Betrag eingeben" value={value} onChange={(evt) => {setValue(evt.target.value);}} />
                    <span className="input-group-text" id="basic-addon2">€</span>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="descriptionInput" className="form-label">Buchungstext</label>
                <input type="text" className="form-control" id="descriptionInput"
                       placeholder="Guthabenaufladung (bar)" value={description} onChange={(evt) => {setDescription(evt.target.value);}} />
              </div>
            </form>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
              <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={onBookClick}>Buchen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopUpPage;
