import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordResetPage({ axios }) {
  const [requestState, setRequestState] = useState('idle');
  const [ error, setError ] = useState(null);
  const [ result, setResult ] = useState(null);
  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: ''
  });

  const query = useQuery();
  const username = query.get('usr');
  const passwordResetToken = query.get('t');

  const history = useHistory();

  useEffect(() => {
    console.log('check token', username, passwordResetToken)
  }, []);

  const onSignInClick = async () => {
    if (formData.password.length < 5) {
      setError('Das eingegbene Kennwort ist zu kurz (mind. 5 Zeichen).');
      return;
    }

    if (formData.password !== formData.passwordConfirmation) {
      setError('Die Kennwörter stimmen nicht überein.');
      return;
    }

    setRequestState('pending');
    setError(null);
    try {
      const response = await axios.post('/authenticate/resetPassword', {
        username: username,
        password: formData.password,
        passwordResetToken: passwordResetToken
      });

      history.replace('/')
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.data) {
        setError(e.response.data.message || e.message);
      } else {
        setError(e.message);
      }
    } finally {
      setRequestState('idle');
    }
  };

  const onInputChange = (evt) => {
    evt.stopPropagation();
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    });
  };

  if (!passwordResetToken || ! username) {
    return <Redirect to="/" />;
  }

  return (
    <div className="d-flex align-items-center justify-content-center h-100 w-100 position-absolute">
      <div className="p-4 bg-white rounded w-100" style={{maxWidth: 400}}>
        <h2>PASSWORT VERGEBEN</h2>
        {
          error ?
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
              { error }
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            : null
        }
        <div className="form-group">
          <input type="password" className="form-control" placeholder="Neues Kennwort" name="password" value={formData.password} onChange={onInputChange} />
        </div>
        <div className="form-group">
          <input type="password" className="form-control" placeholder="Neues Kennwort bestätigen" name="passwordConfirmation" value={formData.passwordConfirmation} onChange={onInputChange} />
        </div>
        <button className="btn btn-block btn-primary mb-3" onClick={onSignInClick} disabled={requestState !== 'idle'}>Übernehmen</button>
        <div className="text-center">
          <small className="text-muted">CLE-Event Webapp v0.1.0</small>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetPage;
