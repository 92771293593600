import './ProcessingOverlay.scss';

function ProcessingOverlay({ text }) {
  return (
    <div className="processing-overlay">
      <div className="processing-overlay-inner">
        <div className="spinner-border text-light mb-3" style={{width: '3rem', height: '3rem'}} role="status">
        </div>
        <div><strong>{text}</strong></div>
      </div>
    </div>
  )
}

export default ProcessingOverlay;
