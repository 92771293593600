import { useEffect, useState } from 'react';
import EuroFormatter from '../EuroFormatter.utils';

function ReportingPage({ axios }) {

  const [ data, setData ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/reporting/general?s=2021-08-22T12:00:00&e=2021-08-23T12:00:00`);
      setData(response.data);
    };

    fetchData();
  }, []);

  if (!data) {
    return (
      <div className="container-fluid">
        <h2>REPORTING</h2>
        <div className="container">
          Lade Daten ...
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <h2>REPORTING</h2>
      <div className="container">
        <section>
          <h4>Transaktionen pro Stunde (nur Gäste)</h4>
          <table className="table table-sm">
            <thead>
            <tr>
              <th>Uhrzeit</th>
              <th>Anzahl</th>
            </tr>
            </thead>
            <tbody>
            {
              [
                ...data.transaktionenNurGaesteProStunde.filter(({ _id: hour }) => hour >= 12).sort((a, b) => a._id - b._id),
                ...data.transaktionenNurGaesteProStunde.filter(({ _id: hour }) => hour < 12 && hour >= 0).sort((a, b) => a._id - b._id)
              ].map(({ _id: hour, count }) => (
                <tr>
                  <td>{`${hour}:00 - ${(hour + 1) % 24}:00`}</td>
                  <td>{count}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </section>
        <section>
          <h4>Umsatz pro Stunde (nur Gäste)</h4>
          <table className="table table-sm">
            <thead>
            <tr>
              <th>Uhrzeit</th>
              <th>Umsatz</th>
            </tr>
            </thead>
            <tbody>
            {
              [
                ...data.umsatzNurGaesteProStunde.filter(({ _id: hour }) => hour >= 12).sort((a, b) => a._id - b._id),
                ...data.umsatzNurGaesteProStunde.filter(({ _id: hour }) => hour < 12 && hour >= 0).sort((a, b) => a._id - b._id)
              ].map(({ _id: hour, sum }) => (
                <tr>
                  <td>{`${hour}:00 - ${(hour + 1) % 24}:00`}</td>
                  <td>{EuroFormatter.format(sum)}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </section>
        <section>
          <h4>Verkäufe (nur Gäste)</h4>
          <table className="table table-sm">
            <thead>
            <tr>
              <th>Artikel</th>
              <th>Anzahl</th>
              <th>Umsatz</th>
            </tr>
            </thead>
            <tbody>
            {
              data.verkaeufeGaeste.map(({ count, item, umsatz }) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{count}</td>
                  <td>{EuroFormatter.format(umsatz)}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
}

export default ReportingPage;