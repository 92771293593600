import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import './GuestInfoPage.scss';
import GuestDataForm from '../GuestDataForm';
import EuroFormatter from '../EuroFormatter.utils';
import ProcessingOverlay from '../ProcessingOverlay/ProcessingOverlay';
import { Helmet } from 'react-helmet';

function GuestInfoPage({ axios, eventName }) {
  const { username } = useParams();

  const [ userdata, setUserdata ] = useState({});

  const [ transactionsOutputPreprocessed, setTransactionsOutputPreprocessed ] = useState([]);

  const [ fetchingUserdata, setFetchingUserdata ] = useState('pending');

  const history = useHistory();

  const fetchUserData = async () => {
    setFetchingUserdata('pending');
    const userData = await axios.get(`/users/${username}`);
    userData.data.accountBalance = EuroFormatter.format(userData.data.accountBalance);
    setUserdata(userData.data);

    const cancellationTransactions = userData.data.transactions.filter((transaction) => transaction.type === 'cancellation')
      .reduce((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue._id]: currentValue
      }), {});

    const transactionsPreprocessed = userData.data.transactions.filter((transaction) => transaction.type !== 'cancellation')
      .map((transaction) => {
        if (transaction.transactionState === 'cancelled') {
          return {
            ...transaction,
            cancellationTransaction: cancellationTransactions[transaction.referenceTransaction]
          }
        }

        return transaction;
      });

    setTransactionsOutputPreprocessed(transactionsPreprocessed);

    setFetchingUserdata('idle');
  };

  const deleteCardBinding = async (cardId) => {
    try {
      await axios.delete(`/users/${userdata._id}/cardIds/${cardId}`);
      fetchUserData();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [])

  if (fetchingUserdata !== 'idle') {
    return (
      <ProcessingOverlay text="Lade Daten" />
    );
  }

  return (
    <div className="container-fluid mb-4">
      <Helmet>
        <title>User Information - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>USER INFORMATION</h2>
      <div className="row">
        <h4 className="col-md-6 text-muted">{username.toUpperCase()}</h4>
        <div className="col-md-6 text-right">
          <Link to={`/top-up/${username}`} className="btn btn-outline-primary btn-sm mx-2" role="button">
            Guthaben aufladen
          </Link>
          <Link to={`/guests/${username}/newTransaction`} role="button" className="btn btn-outline-primary btn-sm mr-2">
            Transaktion erstellen
          </Link>
          <Link to={`/guests/${username}/edit`} role="button" className="btn btn-outline-primary btn-sm">
            Bearbeiten
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-5">
          <div className="card">
            <div className="card-header">
              USER DATEN
            </div>
            <div className="card-body">
              <div className="card-text">
                <div>
                  <div className="text-muted"><small>USERNAME</small></div>
                  <div>{userdata.usernameDisplay}</div>
                </div>
                <div>
                  <div className="text-muted"><small>INTERNAL USERNAME</small></div>
                  <div>{userdata.username}</div>
                </div>
                <div>
                  <div className="text-muted"><small>VORNAME</small></div>
                  <div>{userdata.firstName}</div>
                </div>
                <div>
                  <div className="text-muted"><small>NACHNAME</small></div>
                  <div>{userdata.lastName}</div>
                </div>
                <div>
                  <div className="text-muted"><small>E-MAIL</small></div>
                  <div>{userdata.email}</div>
                </div>
                <div>
                  <div className="text-muted"><small>USER TYPE</small></div>
                  <div>{userdata.type}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-2 mt-md-4">
            <div className="card-header">
              ABRECHNUNGSINFORMATIONEN
            </div>
            <div className="card-body">
              <div className="card-text">
                <div>
                  <div className="text-muted"><small>KONTOSTAND</small></div>
                  <div>{userdata.accountBalance}</div>
                </div>
                <div>
                  <div className="text-muted"><small>KONTO TYP</small></div>
                  <div>{userdata.account.type}</div>
                </div>
                <div>
                  <div className="text-muted"><small>TOKEN FÜR UMSATZABFRAGE</small></div>
                  <div className="text-monospace">{userdata.account.reportToken || <span className="badge bg-light">DEAKTIVIERT</span>}</div>
                </div>
                <div>
                  <div className="text-muted"><small>RFID UID</small></div>
                  {
                    !userdata.cardId || userdata.cardId.length === 0 ? <span className="badge bg-warning text-light">NICHT ZUGEWIESEN</span> :
                      userdata.cardId.map((cardId) =>
                        <div key={cardId} className="text-monospace">
                          {cardId.match(/.{1,2}/g).join(':')}&nbsp;
                          <button type="button" className="btn btn-link" onClick={deleteCardBinding.bind(null, cardId)}><small>löschen</small></button>
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="card mt-2 mt-md-0">
            <div className="card-header">
              TRANSAKTIONEN
            </div>
            <div className="card-body">
              {
                transactionsOutputPreprocessed.map((transaction) =>
                  <div style={transaction.cancellationTransaction ? {borderLeft: '5px solid #007bff'} : null} className="pl-2 transaction-overview-item-wrapper">
                    {
                      transaction.cancellationTransaction ?
                        <Link className="transaction-overview-item" to={`/transactions/${transaction.cancellationTransaction._id}`} key={transaction._id}>
                          <div>
                            <div>{new Date(transaction.cancellationTransaction.createdAt).toLocaleString()} { transaction.cancellationTransaction.type === 'cancellation' ? '[STORNO]' : null }</div>
                            <div className="text-muted"><small>Terminal: {transaction.cancellationTransaction.terminal.name}</small></div>
                          </div>
                          <div>
                            <strong className={transaction.cancellationTransaction.transactionTotal < 0 ? 'text-success' : 'text-danger'}>
                              {EuroFormatter.format(Math.abs(transaction.cancellationTransaction.transactionTotal))}
                            </strong>
                          </div>
                        </Link> : null
                    }
                    <Link className="transaction-overview-item" to={`/transactions/${transaction._id}`} key={transaction._id}>
                      <div>
                        <div>{new Date(transaction.createdAt).toLocaleString()} { transaction.type === 'cancellation' ? '[STORNO]' : null }</div>
                        <div className="text-muted"><small>Terminal: {transaction.terminal.name}</small></div>
                      </div>
                      <div>
                        <strong className={transaction.transactionTotal < 0 ? 'text-success' : 'text-danger'}>
                          {EuroFormatter.format(Math.abs(transaction.transactionTotal))}
                        </strong>
                      </div>
                    </Link>
                  </div>
                )
              }
            </div>
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestInfoPage;
