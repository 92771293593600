import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NewDepositPage({ axios, eventName }) {
  const [ requestState, setRequestState ] = useState('idle');
  const [ depositName, setDepositName ] = useState('');
  const [ depositValue, setDepositValue ] = useState('');
  const [ pageError, setPageError ] = useState(null);

  const history = useHistory();

  const handleSubmitClick = async () => {
    setPageError(null);

    if (depositName.length < 3) {
      return;
    }

    setRequestState('pending');
    try {
      const deposit = await axios.post('/deposits', {
        name: depositName,
        value: depositValue
      });

      history.replace({
        pathname: `/deposits`
      }, {
        flash: {
          success: {
            title: 'Pfandkategorie angelegt',
            body: `Die Pfandkategorie "${depositName}" wurde erfolgreich angelegt.`
          }
        }
      })
    } catch (e) {
      if (e.isAxiosError && e.response.data.message) {
        setPageError(e.response.data.message);
      } else {
        setPageError(e.message);
      }
    }

    setRequestState('idle');
  }

  const handleInputChange = (evt) => {

  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Pfandkategorie anlegen - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>PFANDKATEGORIE ANLEGEN</h2>
      <div className="container">
        {
          pageError ?
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
              <strong>Beim Anlegen der Pfandkategorie ist ein Fehler aufgetreten!</strong><br /><span>{ pageError }</span>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            : null
        }
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputDepositName">Bezeichnung</label>
                <input type="text" className="form-control" id="inputDepositName" name="name" placeholder="Becherpfand (0,50 €)" value={depositName} onChange={(evt) => {setDepositName(evt.target.value);}} />
              </div>
              <div className="form-group">
                <label htmlFor="inputDepositValue">Pfandsatz</label>
                <div className="input-group">
                  <input type="number" min="0.00" step="0.01" className="form-control"
                                                    id="inputDepositValue" name="value" placeholder="1,50" value={depositValue} onChange={(evt) => {setDepositValue(evt.target.value);}} />
                  <div className="input-group-append"><span className="input-group-text">€</span></div>
                  </div>
              </div>
            </form>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
              <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={handleSubmitClick} disabled={requestState !== 'idle'}>Anlegen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewDepositPage;
