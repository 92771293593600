import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Bootstrap JavaScript Utils
import '@popperjs/core/dist/umd/popper';
import 'bootstrap/dist/js/bootstrap';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: {
          'Account Balance Inquiry': 'Account Balance Inquiry',
          'Loading Data': 'Loading Data',
          'Balance Info': 'Balance Info',
          'Your Account Balance': 'Your Account Balance'
        }
      },
      de: {
        translation: {
          'Account Balance Inquiry': 'Guthabenabfrage',
          'Loading Data': 'Lade Daten',
          'Balance Info': 'Umsatzabfrage',
          'Your Account Balance': 'Dein aktuelles Guthaben beträgt'
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
