import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import AppAuthenticated from './AppAuthenticated';
import SignInPage from './SignInPage/SignInPage';
import './App.scss';
import AppSetup from './AppSetup';
import ForgotPasswordPage from './SignInPage/ForgotPasswordPage';
import PasswordResetPage from './SignInPage/PasswordResetPage';
import PublicBalanceInfoPage from './PublicBalanceInfoPage/PublicBalanceInfoPage';
import ProcessingOverlay from './ProcessingOverlay/ProcessingOverlay';
import config from './config';
import LeaderBoardPage from './LeaderBoardPage/LeaderBoardPage';
import ReportingPage from './ReportingPage/ReportingPage';


function App() {

  const [jwt, setJWT] = useState(localStorage.getItem('token'));
  const [appSetup, setAppSetup] = useState(true);
  const [axiosInstance, setAxiosInstance] = useState(null);

  useEffect(() => {
    const instance = axios.create({
      baseURL: config.baseURL
    });

    if (jwt) {
      instance.defaults.headers.authorization = `Bearer ${jwt}`;
    } else {
      instance.defaults.headers.authorization = null;
    }

    instance.interceptors.response.use(
      (response) => response,
      (error) => {

        if (error.isAxiosError && error.response.status === 401) {
          if (error.response.data.message.includes('JsonWebTokenError')) {
            localStorage.removeItem('token');
            setJWT(null);
          }
        }

        throw error;
    });

    setAxiosInstance({
      axios: instance
    });
    setAppSetup(false);
  }, [jwt]);

  if (appSetup) {
    return <AppSetup />;
  }

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/accounts/:username/balance"><PublicBalanceInfoPage axios={axiosInstance.axios} /></Route>
          <Route path="/leaderboard"><LeaderBoardPage axios={axiosInstance.axios} /></Route>
          <Route path="/auth/passwordReset"><PasswordResetPage axios={axiosInstance.axios} /></Route>
          <Route path="/auth/forgotPassword"><ForgotPasswordPage axios={axiosInstance.axios} /></Route>
          <Route path="/sign-in"><SignInPage axios={axiosInstance.axios} setJWT={setJWT} jwt={jwt} /></Route>

          <Route path="/reporting"><ReportingPage axios={axiosInstance.axios} /></Route>

          {
            jwt ?
              <Suspense fallback={<ProcessingOverlay text="Lade Applikation" />}>
                <AppAuthenticated axios={axiosInstance.axios} setJWT={setJWT} />
              </Suspense>
              : null
          }
          <Redirect to="/sign-in" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
