import { useParams } from 'react-router-dom';
import EuroFormatter from '../EuroFormatter.utils';
import { useEffect, useState } from 'react';
import useQuery from '../useQuery';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function PublicBalanceInfoPage({ axios }) {

  const [ balance, setBalance ] = useState(0);
  const [ fetchingData, setFetchingData ] = useState('pending');
  const [ pageError, setPageError ] = useState(null);

  const { username } = useParams();
  const query = useQuery();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setFetchingData('pending');
      try {
        const response = await axios.get(`/users/${username}/accountBalance?t=${query.get('t')}`);
        setBalance(response.data.balance);
      } catch (e) {
        if (e.isAxiosError && e.response && e.response.data.message) {
          setPageError(e.response.data.message);
        } else {
          setPageError(e.message);
        }
      } finally {
        setFetchingData('idle');
      }
    };

    fetchData();
  }, [])

  const renderBalanceCard = () => {
    if (fetchingData === 'idle') {
      return (
        <div className="card mb-4">
          <div className="card-body">
            <div className="text-center">{ t('Your Account Balance').toUpperCase() }</div>
            <div className="text-center" style={{fontSize: '3em'}}>{EuroFormatter.format(Math.abs(balance))}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className="text-center"><div className="spinner-border mb-2" style={{ width: '3rem', height: '3rem' }} role="status" /></div>
          <div className="text-center">{ t('Loading Data').toUpperCase() }</div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-3">
      <Helmet>
        <title>{ t('Account Balance Inquiry') } | CLE WEB</title>
      </Helmet>
      <h2>{t('Balance Info').toUpperCase()}</h2>
      <h4 className="text-muted text-uppercase">{username}</h4>
      {
        pageError ?
          <div className="alert alert-info alert-dismissible fade show" role="alert">
            <strong>Umsatzabfrage nicht möglich</strong><br /><span>{ pageError }</span>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> : renderBalanceCard()
      }
      <div className="text-center text-muted">
        <small>Eventportal Webapp v0.1.0</small>
      </div>
    </div>
  )
}

export default PublicBalanceInfoPage;
