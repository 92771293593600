import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function GuestListPage({ axios, eventName }) {
  const [users, setUsers] = useState([]);
  const [fetchingUsers, setFetchingUsers] = useState(true);
  const [userFilter, setUserFilter] = useState('');

  const history = useHistory();

  const fetchUsers = async () => {
    setFetchingUsers(true);
    const users = await axios.get('/users');
    setUsers(users.data);
    setFetchingUsers(false);
  };

  const handleModalClose = () => {
    console.log('Modal Closed');
  }

  const handleUserFilterChange = (evt) => {
    evt.preventDefault();
    setUserFilter(evt.target.value);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Gästeliste - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>GÄSTELISTE</h2>
      <div className="row">
        <div className="col-sm-4">
          <input className="form-control w-100" type="text" placeholder="Suche" value={userFilter} onChange={handleUserFilterChange} />
        </div>
        <div className="col-sm-3 ml-auto text-right mt-1 mt-sm-0">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => {history.push('/guests/new');}}>
            Gast anlegen
          </button>
        </div>
      </div>

      <table className="table table-hover mt-4">
        <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col" className="d-none d-md-table-cell">Vorname</th>
          <th scope="col" className="d-none d-md-table-cell">Nachname</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {
          fetchingUsers ?
            <tr>
              <th colSpan={4} style={{textAlign: 'center'}}>
                <div className="spinner-border mb-2" role="status" />
                <div>LADE DATEN</div>
              </th>
            </tr> :
            users.filter((user) => {
              return !userFilter || user.username.toLowerCase().includes(userFilter.toLowerCase()) || user.firstName.toLowerCase().includes(userFilter.toLowerCase()) || user.lastName.toLowerCase().includes(userFilter.toLowerCase())
            }).map((user) => (
              <tr key={user.username}>
                <th scope="row">{user.usernameDisplay}</th>
                <td className="d-none d-md-table-cell">{ user.firstName }</td>
                <td className="d-none d-md-table-cell">{ user.lastName }</td>
                <td className="text-right">
                  <Link to={`/guests/${user.username}`} className="btn btn-outline-primary btn-sm mx-1" title="Konto">ℹ</Link>
                  <Link to={`/guests/${user.username}/edit`} className="btn btn-outline-secondary btn-sm mx-1" title="Bearbeiten">✎</Link>
                  <Link to={`/guests/${user.username}/delete`} className="btn btn-outline-danger btn-sm mx-1" title="Löschen">🗑</Link>
                </td>
              </tr>
            ))
        }
        </tbody>
      </table>
    </div>
  );
}

export default GuestListPage;
