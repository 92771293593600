import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import validator from 'validator';
import { Helmet } from 'react-helmet';

function GuestEditPage({ axios, eventName }) {

  const [ userdata, setUserdata ] = useState({
    usernameDisplay: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    accountType: 'discharge',
    userType: 'guest',
    createValueURL: false,
    sendEmail: false
  });

  const [ errors, setErrors ] = useState({
    usernameDisplay: null,
    username: null,
    firstName: null,
    lastName: null,
    email: null
  });

  const [ pageError, setPageError ] = useState(null);

  const [ requestState, setRequestState ] = useState('idle');
  const [ fetchState, setFetchState ] = useState('pending');

  const onDataChange = (evt) => {
    if (evt.target.type === 'checkbox') {
      setUserdata({
        ...userdata,
        [evt.target.name]: evt.target.checked
      })
    } else if (evt.target.name === 'usernameDisplay') {
      setUserdata({
        ...userdata,
        [evt.target.name]: evt.target.value,
        username: evt.target.value.trim().toLowerCase().replaceAll(' ', '-')
      })
    } else {
      setUserdata({
        ...userdata,
        [evt.target.name]: evt.target.value
      })
    }
  }

  const handleSubmitClick = async () => {
    setPageError(null);

    const usernameError = userdata.usernameDisplay.length < 3 ? 'Username ungültig' : null;
    const firstNameError = !validator.isAlphanumeric(userdata.firstName, 'de-DE') || userdata.usernameDisplay.firstName < 3 ? 'Vorname ungültig' : null;
    const lastNameError = !validator.isAlphanumeric(userdata.lastName, 'de-DE') || userdata.usernameDisplay.lastName < 3 ? 'Nachname ungültig' : null;
    const emailError = !validator.isEmail(userdata.email) ? 'E-Mail ungültig' : null;

    setErrors({
      ...errors,
      usernameDisplay: usernameError,
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError
    });

    if (usernameError || firstNameError || lastNameError || emailError) {
      return;
    }

    setRequestState('pending');
    try {
      const response = await axios.post('/users', {
        usernameDisplay: userdata.usernameDisplay,
        username: userdata.username,
        email: userdata.email,
        firstName: userdata.firstName,
        lastName: userdata.lastName,
        type: userdata.userType,
        account: {
          type: userdata.accountType
        },
        sendAccountTokenEmail: userdata.sendEmail
      });

      history.replace({
        pathname: `/guests/${response.data.username}`
      })
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.data.message) {
        setPageError(e.response.data.message);
      } else {
        setPageError(e.message);
      }
    }

    setRequestState('idle');
  }

  const { username } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setFetchState('pending');
      try {
        const userData = await axios.get(`/users/${username}`).then(response => response.data);
        setUserdata({
          usernameDisplay: userData.usernameDisplay,
          username: userData.username,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          accountType: userData.account.type,
          userType: userData.type
        });
      } catch (e) {

      } finally {
        setFetchState('idle');
      }
    }

    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Gast bearbeiten - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>USER BEARBEITEN</h2>
      <h4 className="text-muted">{username.toUpperCase()}</h4>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <form className="needs-validation" noValidate={true}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputUsernameDisplay">Username</label>
                  <input type="text" className="form-control" id="inputUsernameDisplay" name="usernameDisplay"
                         value={ userdata.usernameDisplay } onChange={ onDataChange }/>
                  <div className="invalid-feedback">
                    Please choose a username.
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputInternalUsername">Interner Username</label>
                  <input type="text" className="form-control" id="inputInternalUsername" disabled={ true }
                         name="username"
                         value={ userdata.username } onChange={ onDataChange }/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="inputFirstName">Vorname</label>
                  <input type="text" className="form-control" id="inputFirstName" name="firstName"
                         value={ userdata.firstName } onChange={ onDataChange }/>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="inputLastName">Nachname</label>
                  <input type="text" className="form-control" id="inputLastName" name="lastName"
                         value={ userdata.lastName } onChange={ onDataChange }/>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">E-Mail</label>
                <input type="email" className="form-control" id="inputEmail" name="email" value={ userdata.email }
                       onChange={ onDataChange }/>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="selectAccountType">Konto-Typ</label>
                  <select className="form-control" id="selectAccountType" name="accountType" onChange={ onDataChange }
                          value={ userdata.accountType }>
                    <option value="discharge">Prepaid</option>
                    <option value="charge">Postpaid</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="selectUserType">User-Typ</label>
                  <select className="form-control" id="selectUserType" name="userType" onChange={ onDataChange }
                          value={ userdata.userType }>
                    <option value="guest">Gast</option>
                    <option value="team">Team</option>
                    <option value="admin">Admin</option>
                  </select>
                  {
                    userdata.userType === 'admin' ? (
                      <small id="selectUserTypeHelpBlock" className="form-text text-muted">
                        Das System sendet dem User eine Einladung per E-Mail mit der er sein Passwort zum Zugriff auf
                        das Admin Panel setzen kann.
                      </small>
                    ) : null
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="createAccountStatementTokenSwitch"
                         name="createValueURL" checked={ userdata.createValueURL } onChange={ onDataChange }/>
                  <label className="custom-control-label" htmlFor="createAccountStatementTokenSwitch">Link zur
                    Umsatzabfrage generieren</label>
                </div>
              </div>
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="sendAccountStatementTokenEmailSwitch"
                         name="sendEmail" checked={ userdata.sendEmail } onChange={ onDataChange }/>
                  <label className="custom-control-label" htmlFor="sendAccountStatementTokenEmailSwitch">E-Mail mit
                    Infos zur Umsatzabfrage versenden</label>
                </div>
              </div>
            </form>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
              <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={handleSubmitClick} disabled={requestState !== 'idle'}>Anlegen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestEditPage;
