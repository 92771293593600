import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NewCategoryPage({ axios, eventName }) {
  const [requestState, setRequestState] = useState('idle');
  const [ categoryName, setCategoryName ] = useState('');
  const [ pageError, setPageError ] = useState(null);

  const history = useHistory();

  const handleSubmitClick = async () => {
    setPageError(null);

    if (categoryName.length < 3) {
      return;
    }

    setRequestState('pending');
    try {
      const category = await axios.post('/categories', {
        name: categoryName
      });

      history.replace({
        pathname: `/items/categories`
      }, {
        flash: {
          success: {
            title: 'Kategorie angelegt',
            body: `Die Kategorie "${categoryName}" wurde erfolgreich angelegt.`
          }
        }
      })
    } catch (e) {
      if (e.isAxiosError && e.response.data.message) {
        setPageError(e.response.data.message);
      } else {
        setPageError(e.message);
      }
    }

    setRequestState('idle');
  }

  const handleInputChange = (evt) => {

  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Artikelkategorie anlegen - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>ARTIKELKATEGORIE ANLEGEN</h2>
      <div className="container">
        {
          pageError ?
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
              <strong>Beim Anlegen des Users ist ein Fehler aufgetreten!</strong><br /><span>{ pageError }</span>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            : null
        }
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputItemName">Bezeichnung</label>
                <input type="text" className="form-control" id="inputItemName" name="name" placeholder="Longdrinks" value={categoryName} onChange={(evt) => {setCategoryName(evt.target.value);}} />
              </div>
            </form>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
              <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={handleSubmitClick} disabled={requestState !== 'idle'}>Anlegen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCategoryPage;
