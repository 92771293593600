import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import EuroFormatter from '../EuroFormatter.utils';
import { Helmet } from 'react-helmet';

function NewItemPage({ axios, eventName }) {
  const [ requestState, setRequestState ] = useState('idle');
  const [ pageError, setPageError ] = useState(null);
  const [ itemData, setItemData ] = useState({});

  const history = useHistory();

  const loadDepositOptions = async () => {
    const deposits = await axios.get('/deposits');
    return deposits.data
      .map(({_id: id, name, price}) => ({ value: id, label: `${name} (${EuroFormatter.format(price)})` }));
  }

  const loadCategoryOptions = async () => {
    const categories = await axios.get('/categories');
    return categories.data
      .filter((category) => category.visible)
      .map(({_id: id, name}) => ({ value: id, label: name }));
  }

  const handleSubmitClick = async () => {
    setPageError(null);

    /*if (categoryName.length < 3) {
      return;
    }*/

    setRequestState('pending');
    try {
      const item = await axios.post('/items', {
        name: itemData.name,
        category: itemData.category.value,
        price: itemData.price,
        deposit: itemData.deposit.value
      });

      history.replace({
        pathname: `/items`
      }, {
        flash: {
          success: {
            title: 'Artikel angelegt',
            body: `Der Artikel "${itemData.name}" wurde erfolgreich angelegt.`
          }
        }
      })
    } catch (e) {
      if (e.isAxiosError && e.response.data.message) {
        setPageError(e.response.data.message);
      } else {
        setPageError(e.message);
      }
    }

    setRequestState('idle');
  }

  const handleInputChange = (evt) => {
    setItemData({
      ...itemData,
      [evt.target.name]: evt.target.value
    });
  }

  const handleSelectChange = (name, data) => {
    setItemData({
      ...itemData,
      [name]: data
    });
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Event anlegen - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>ARTIKEL ANLEGEN</h2>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputItemName">Bezeichnung</label>
                <input type="text" className="form-control" id="inputItemName" name="name" placeholder="Gin Tonic (33cl)" value={itemData.name} onChange={handleInputChange} />
              </div>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="inputItemPrice">Preis</label>
                  <div className="input-group">
                    <input type="number" min="0.00" step="0.01" className="form-control" id="inputItemPrice" name="price" placeholder="1,50" value={itemData.price} onChange={handleInputChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">&euro;</span>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-8">
                  <label htmlFor="selectItemDepositCategory">Pfand</label>
                  <AsyncSelect
                    name="depositCategory"
                    cacheOptions
                    loadOptions={loadDepositOptions}
                    defaultOptions
                    onChange={handleSelectChange.bind(null, 'deposit')}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="selectItemCategory">Kategorie</label>
                <AsyncSelect
                  cacheOptions
                  name="category"
                  loadOptions={loadCategoryOptions}
                  defaultOptions
                  onChange={handleSelectChange.bind(null, 'category')}
                />
              </div>
            </form>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
              <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={handleSubmitClick} disabled={requestState !== 'idle'}>Anlegen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewItemPage;
