import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qrCodeIcon from '../qr-code.png';
import { Helmet } from 'react-helmet';

function TerminalListPage({ axios, eventName }) {
  const history = useHistory();

  const [terminals, setTerminals] = useState([]);
  const [terminalsFetch, setTerminalsFetch] = useState('pending');

  useEffect(() => {
    const fetchTerminals = async () => {
      setTerminalsFetch('pending');
      const items = await axios.get('/terminals');
      setTerminals(items.data);
      setTerminalsFetch('idle');
    }

    fetchTerminals();
  }, [])

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Terminal Liste - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>TERMINALS</h2>
      <div className="row">
        <div className="col-sm-4">
          <input className="form-control w-100" type="text" placeholder="Suche" />
        </div>
        <div className="col-sm-4 mt-1 mt-sm-0"></div>
        <div className="col-sm-3 ml-auto text-right mt-1 mt-sm-0">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => {history.push('/terminals/new')}}>
            Terminal anlegen
          </button>
        </div>
      </div>

      <table className="table table-hover mt-4">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Ort</th>
          <th scope="col">Tag</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {
          terminals.map((terminal) => (
            <tr key={ terminal._id }>
              <th scope="row">{ terminal.name }</th>
              <td>{ terminal.location || 'n/a' }</td>
              <td>
                <div className="badge bg-primary text-light">{ terminal.tag }</div>
              </td>
              <td className="text-right">
                <button className="btn btn-outline-secondary btn-sm mx-1" title="QR Code anzeigen" onClick={ () => {
                  history.push(`/terminals/${ terminal._id }`)
                } }><img src={qrCodeIcon} style={{width: 20}} />
                </button>
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
}

export default TerminalListPage;
