import React from 'react';
import { Link, NavLink } from 'react-router-dom';

function Navbar({ setJWT, eventName }) {
  const onLogoutClick = () => {
    localStorage.removeItem('token');
    setJWT(null);
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <Link className="navbar-brand text-uppercase" to="/">{ eventName }</Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
              aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
            <NavLink className="nav-link dropdown-toggle" to="/guests" id="navbarGuestsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Gäste
            </NavLink>
            <div className="dropdown-menu" aria-labelledby="navbarGuestsDropdown">
              <Link className="dropdown-item" to="/guests">Gästeliste</Link>
              <Link className="dropdown-item" to="/guests/new">Gast anlegen</Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/guests/import">Gäste importieren</Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <NavLink className="nav-link dropdown-toggle" to="/items" id="navbarArticlesDropdown" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
              Artikel
            </NavLink>
            <div className="dropdown-menu" aria-labelledby="navbarArticlesDropdown">
              <Link className="dropdown-item" to="/items">Artikelliste</Link>
              <Link className="dropdown-item" to="/items/new">Artikel anlegen</Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/items/categories">Kategorien</Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/deposits">Pfandkategorien</Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            <NavLink className="nav-link dropdown-toggle" to="/terminals" id="navbarArticlesDropdown" role="button" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">
              Terminals
            </NavLink>
            <div className="dropdown-menu" aria-labelledby="navbarArticlesDropdown">
              <Link className="dropdown-item" to="/terminals">Terminal Liste</Link>
              <Link className="dropdown-item" to="/terminals/new">Terminal anlegen</Link>
            </div>
          </li>
          {
            /*
            <li className="nav-item">
            <NavLink className="nav-link" to="/stats" tabIndex="-1" aria-disabled="true">Statistik</NavLink>
          </li>
             */
          }
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item">
            <span className="nav-link" role="button" aria-current="page" onClick={onLogoutClick}>Log Out</span>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
