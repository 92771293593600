import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

function SignInPage({ axios, setJWT, jwt }) {
  const [requestState, setRequestState] = useState('idle');
  const [ error, setError ] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const onSignInClick = async () => {
    setRequestState('pending');
    setError(null);
    try {
      const response = await axios.post('/authenticate', formData);
      localStorage.setItem('token', response.data.token);
      setJWT(response.data.token);
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.data) {
        setError(e.response.data.message || e.message);
      } else {
        setError(e.message);
      }
    } finally {
      setRequestState('idle');
    }
  };

  const onInputChange = (evt) => {
    evt.stopPropagation();
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    });
  };

  if (jwt) {
    return <Redirect to="/" />
  }

  return (
    <div className="d-flex align-items-center justify-content-center h-100 w-100 position-absolute">
      <div className="p-4 bg-white rounded w-100" style={{maxWidth: 400}}>
        <h2>LOGIN</h2>
        {
          error ?
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
              { error }
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            : null
        }
        <div className="form-group">
          <input type="text" className="form-control" placeholder="username" name="username" value={formData.username} onChange={onInputChange} />
        </div>
        <div className="form-group">
          <input type="password" className="form-control" placeholder="*****" name="password" value={formData.password} onChange={onInputChange} />
        </div>
        <button className="btn btn-block btn-primary mb-3" onClick={onSignInClick} disabled={requestState !== 'idle'}>Anmelden</button>
        <div className="text-center mb-3">
          <small>
            <Link to="/auth/forgotPassword">Kennwort vergessen?</Link>
          </small>
        </div>
        <div className="text-center">
          <small className="text-muted">CLE-Event Webapp v0.1.0</small>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
