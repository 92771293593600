import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Alert from '../Alert';
import { Helmet } from 'react-helmet';


function EditItemPage({ axios, eventName }) {
  const history = useHistory();
  const location = useLocation();

  const [ itemData, setItemData ] = useState({});
  const [ requestState, setRequestState ] = useState({
    initialFetch: 'pending',
    saveRequest: 'idle'
  });

  useEffect(() => {
    const fetchData = async () => {
      setRequestState({
        ...requestState,
        initialFetch: 'pending'
      });

      try {
        const itemDataResponse = await axios.get(`/items/${location.state.item._id}`);
        setItemData({
          ...itemDataResponse.data,
          deposit: {
            label: itemDataResponse.data.deposit.name,
            value: itemDataResponse.data.deposit._id
          },
          category: {
            label: itemDataResponse.data.category.name,
            value: itemDataResponse.data.category._id
          }
        });
        setRequestState({
          ...requestState,
          initialFetch: 'idle'
        });
      } catch (e) {

      }
    };

    if (location.state) {
      fetchData();
    }
  }, []);

  const loadDepositOptions = async () => {
    const deposits = await axios.get('/deposits');
    return deposits.data
      .map(({_id: id, name}) => ({ value: id, label: name }));
  }

  const loadCategoryOptions = async () => {
    const categories = await axios.get('/categories');
    return categories.data
      .filter((category) => category.visible)
      .map(({_id: id, name}) => ({ value: id, label: name }));
  }

  const handleInputChange = (evt) => {
    setItemData({
      ...itemData,
      [evt.target.name]: evt.target.value
    });
  }

  const handleSelectChange = (name, data) => {
    setItemData({
      ...itemData,
      [name]: data
    });
  }

  const handleSubmitClick = async () => {
    setRequestState({
      ...requestState,
      saveRequest: 'pending'
    });
    try {
      const item = await axios.patch(`/items/${location.state.item._id}`, {
        name: itemData.name,
        category: itemData.category.value,
        price: itemData.price,
        deposit: itemData.deposit.value
      });

      history.replace({
        pathname: `/items`
      }, {
        alert: {
          text: `${itemData.name} erfolgreich aktualisiert`,
          type: 'success'
        }
      });
    } catch (e) {
      if (e.isAxiosError && e.response.data.message) {
        //setPageError(e.response.data.message);
      } else {
        //setPageError(e.message);
      }
    }

    setRequestState({
      ...requestState,
      saveRequest: 'idle'
    });
  }

  if (!location.state) {
    return <Redirect to="/items" />
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Artikel bearbeiten - { eventName } | CLE WEB</title>
      </Helmet>
      <h2 className="text-uppercase">ARTIKEL BEARBEITEN</h2>
      <h4 className="text-muted text-uppercase">{location.state && location.state.item ? location.state.item.name : ''}</h4>
      <div className="container">

        <Alert type="primary">
          <strong>HINWEIS</strong><br />
          Änderungen am Artikel sind nur für zukünftige Buchungen gültig. <br/>
          Rückwirkend werden die Änderungen nicht übernommen.
        </Alert>

        <div className="card">
          {
            requestState.initialFetch !== 'idle' ?
              <div className="card-body">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{height: 300}}>
                  <div className="spinner-border mb-3" style={{width: '3rem', height: '3rem'}} role="status">
                  </div>
                  <strong>Lade aktuelle Daten</strong>
                </div>
              </div> :
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="inputItemName">Bezeichnung</label>
                    <input type="text" className="form-control" id="inputItemName" name="name" placeholder="Gin Tonic (33cl)" value={itemData.name} onChange={handleInputChange} />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputItemPrice">Preis</label>
                      <div className="input-group">
                        <input type="number" min="0.00" step="0.01" className="form-control" id="inputItemPrice" name="price" placeholder="1,50" value={itemData.price} onChange={handleInputChange} />
                        <div className="input-group-append">
                          <span className="input-group-text">&euro;</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-8">
                      <label htmlFor="selectItemDepositCategory">Pfand</label>
                      <AsyncSelect
                        name="depositCategory"
                        cacheOptions
                        loadOptions={loadDepositOptions}
                        defaultOptions
                        onChange={handleSelectChange.bind(null, 'deposit')}
                        value={itemData.deposit}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="selectItemCategory">Kategorie</label>
                    <AsyncSelect
                      cacheOptions
                      name="category"
                      loadOptions={loadCategoryOptions}
                      defaultOptions
                      onChange={handleSelectChange.bind(null, 'category')}
                      value={itemData.category}
                    />
                  </div>
                </form>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
                  <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={handleSubmitClick} disabled={requestState.saveRequest !== 'idle'}>Speichern</button>
                </div>
              </div>
          }
        </div>

      </div>
    </div>
  );
}

export default EditItemPage;
