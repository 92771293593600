import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ItemCategoryListPage({ axios, eventName }) {
  const [ categories, setCategories ] = useState([]);
  const [ categoriesFetch, setCategoriesFetch ] = useState('pending');

  const history = useHistory();

  const onEditCategoryClick = (category) => {
  };

  const onDeleteCategoryClick = (category) => {
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setCategoriesFetch('pending');
      const categories = await axios.get('/categories?itemCount=1');
      setCategories(categories.data.filter((category) => category.visible));
      setCategoriesFetch('idle');
    }

    fetchCategories();
  }, [])

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Produktkategorien - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>PRODUKTKATEGORIEN</h2>

      <div className="row">
        <div className="col-sm-4">
          <input className="form-control w-100" type="text" placeholder="Suche" />
        </div>
        <div className="col-sm-3 ml-auto text-right mt-1 mt-sm-0">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => { history.push('/items/categories/new') }}>
            Kategorie anlegen
          </button>
        </div>
      </div>

      <table className="table table-hover mt-4">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col" className="d-none d-md-table-cell">Anz. Produkte</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {
          categoriesFetch !== 'idle' ?
            <tr>
              <th colSpan={3} style={{textAlign: 'center'}}>
                <div className="spinner-border mb-2" role="status" />
                <div>LADE DATEN</div>
              </th>
            </tr> :
          categories.map((category) => (
            <tr key={category._id}>
              <th scope="row">{category.name}</th>
              <td className="d-none d-md-table-cell">{category.itemCount}</td>
              <td className="text-right">
                {
                  //<button className="btn btn-outline-secondary btn-sm mx-1" title="Bearbeiten" onClick={onEditCategoryClick}>✎</button>
                  //<button className="btn btn-outline-danger btn-sm mx-1" title="Löschen" onClick={onDeleteCategoryClick}>🗑</button>
                }
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>

      <div className="modal fade" id="modalNewItemCategory" data-backdrop="static" data-keyboard="false" tabIndex="-1"
           aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">PRODUKTKATEGORIE ANLEGEN</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="inputItemCategoryName">Bezeichnung</label>
                  <input type="text" className="form-control" id="inputItemCategoryName" name="name" placeholder="Longdrinks" />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
              <button type="button" className="btn btn-primary">Speichern</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalEditItemCategory" data-backdrop="static" data-keyboard="false" tabIndex="-1"
           aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">PRODUKTKATEGORIE BEARBEITEN</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              ...
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
              <button type="button" className="btn btn-primary">Speichern</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalDeleteItemCategoryConfirmation" data-backdrop="static" data-keyboard="false" tabIndex="-1"
           aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">PRODUKTKATEGORIE LÖSCHEN</h5>
            </div>
            <div className="modal-body">
              <p>Dieser Vorgang kann nicht rückgängig gemacht werden.</p>
              <p className="text-muted">Wird die Produktkategorie gelöscht, werden alle Artikel, die in der Kategorie sind, auf unkategorisiert gesetzt.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
              <button type="button" className="btn btn-danger">Löschen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemCategoryListPage;
