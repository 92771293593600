import { useEffect, useState } from 'react';

function ScanLocationsCard({ axios }) {
  const [ dataFetch, setDataFetch ] = useState('pending');
  const [ value, setValue ] = useState([]);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setDataFetch('pending');
      try {
        const response = await axios.get('/stats/topScanLocations');
        setValue(response.data.value);
      } catch (e) {
        if (e.isAxiosError && e.response && e.response.data.message) {
          setError(e.response.data.message);
        } else {
          setError(e.message);
        }
      } finally {
        setDataFetch('idle');
      }
    };

    fetchData();
  }, [])

  return (
    <div className="card text-center">
      <div className="card-body">
        <h5 className="card-title">TOP SCAN LOCATIONS</h5>
        {
          error ?
            <p className="card-text" style={{fontSize: '3rem'}}><strong>Fehler beim Abruf</strong></p> :
          dataFetch !== 'idle' ?
            <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status" /> :
            <table style={{width: '100%'}} className="table table-sm text-left">
              <thead>
              <tr>
                <th scope="col">Terminal</th>
                <th scope="col">Scans</th>
              </tr>
              </thead>
              <tbody>
                {
                  value.map((scanLocation) => <tr key={scanLocation._id}>
                    <td>{scanLocation.terminal}</td>
                    <td>{scanLocation.count}</td>
                  </tr>)
                }
              </tbody>
            </table>
        }
      </div>
    </div>
  );
}

export default ScanLocationsCard;
