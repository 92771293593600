import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Link, useHistory } from 'react-router-dom';
import Alert from '../Alert';
import { Helmet } from 'react-helmet';

function ItemListPage({ axios, eventName }) {

  const [items, setItems] = useState([]);
  const [itemsFetch, setItemsFetch] = useState('pending');

  const [ showModal, setShowModal ] = useState(false);

  const [ articleToDelete, setArticleToDelete ] = useState({});

  const [ filters, setFilters ] = useState({searchString: '', categories: []})

  const [ error, setError ] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const fetchItems = async () => {
      setItemsFetch('pending');
      const items = await axios.get('/items?visibility=1');
      const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
      setItems(items.data.map((item) => ({...item, price: formatter.format(item.price)})));
      setItemsFetch('idle');
    }

    fetchItems();
  }, [])


  const loadOptions = async () => {
    try {
      const categories = await axios.get('/categories');
      return categories.data
        .filter((category) => category.visible)
        .map(({_id: id, name}) => ({ value: id, label: name }));
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.message) {
        setError(e.response.message);
      } else {
        setError(e.message);
      }
    }
  }


  const handleSelectChange = (values) => {
    setFilters({
      ...filters,
      categories: values
    });
  };

  const handleSearchStringInputChange = (evt) => {
    setFilters({
      ...filters,
      searchString: evt.target.value
    });
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Artikelliste - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>ARTIKEL</h2>

      { error ? <Alert text={error} /> : null }

      <div className="row">
        <div className="col-sm-4">
          <input className="form-control w-100" type="text" name="searchString" onChange={handleSearchStringInputChange} placeholder="Suche" />
        </div>
        <div className="col-sm-4 mt-1 mt-sm-0">
          <AsyncSelect
            cacheOptions
            isMulti
            loadOptions={loadOptions}
            defaultOptions
            onChange={handleSelectChange}
            placeholder="Kategorie filtern"
          />
        </div>
        <div className="col-sm-3 ml-auto text-right mt-1 mt-sm-0">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => {history.push('/items/new')}}>
            Artikel anlegen
          </button>
        </div>
      </div>

      <table className="table table-hover mt-4">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Kategorie</th>
          <th scope="col">Preis</th>
          <th scope="col">Pfand</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {
          itemsFetch !== 'idle' ?
            <tr>
              <th colSpan={5} style={{textAlign: 'center'}}>
                <div className="spinner-border mb-2" role="status" />
                <div>LADE DATEN</div>
              </th>
            </tr> :
          items
            .filter((item) => filters.searchString.length === 0 || item.name.toLowerCase().includes(filters.searchString.toLowerCase()))
            .filter((item) => filters.categories.length === 0 || filters.categories.map((categories) => categories.value).includes(item.category._id))
            .map((item) => (
            <tr key={item._id}>
              <th scope="row">{item.name}</th>
              <td>{item.category.name}</td>
              <td>{item.variablePrice ? 'variabel' : item.price}</td>
              <td>{ item.deposit ? item.deposit.name : 'ohne' }</td>
              <td className="text-right">
                {
                  item.removable ?
                    <><button className="btn btn-outline-secondary btn-sm mx-1" title="Bearbeiten" onClick={() => {history.push(`/items/${item._id}/edit`, {
                      item: item
                    })}}>✎</button>
                    <button className="btn btn-outline-danger btn-sm mx-1" title="Löschen" onClick={() => {history.push(`/items/${item._id}/delete`, {
                      item: item
                    })}}>🗑</button></> : null
                }

              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </div>
  );
}

export default ItemListPage;
