import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NewTerminalPage({ axios, eventName }) {

  const [terminalData, setTerminalData] = useState({
    name: '',
    location: ''
  });
  const [requestState, setRequestState] = useState('idle');

  const history = useHistory();

  const handleInputChange = (evt) => {
    setTerminalData({
      ...terminalData,
      [evt.target.name]: evt.target.value
    });
  }

  const handleSubmitClick = async () => {
    setRequestState('pending');
    const response = await axios.post('/terminals', terminalData);
    debugger;
    setRequestState('idle');
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Terminal anlegen - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>TERMINAL ANLEGEN</h2>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputTerminalName">Name</label>
                <input type="text" className="form-control" id="inputTerminalName" name="name" placeholder="Kasse 1" value={terminalData.name} onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <label htmlFor="inputTerminalLocation">Ort</label>
                <input type="text" className="form-control" id="inputTerminalLocation" name="location" placeholder="Partyraum" value={terminalData.location} onChange={handleInputChange} />
              </div>
            </form>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="order-2 order-md-1 m-2 btn btn-secondary me-md-2" onClick={() => {history.goBack()}}>Abbrechen</button>
              <button className="order-1 order-md-2 m-2 btn btn-primary" onClick={handleSubmitClick} disabled={requestState !== 'idle'}>Anlegen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTerminalPage;
