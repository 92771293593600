import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import EuroFormatter from './EuroFormatter.utils';
import { Helmet } from 'react-helmet';

function TransactionDetailPage({ axios, eventName }) {
  const { transactionId } = useParams();

  const [ editMode, setEditMode ] = useState(false);

  const [ transaction, setTransaction ] = useState({});

  const [ fetchTransaction, setFetchTransaction ] = useState('pending');

  const history = useHistory();

  useEffect(() => {
    const fetchTransaction = async () => {
      setFetchTransaction('pending');
      const transaction = await axios.get(`/transactions/${transactionId}`);
      setTransaction(transaction.data);
      setFetchTransaction('idle');
    }

    fetchTransaction();
  }, [transactionId])

  const onCancelTransactionClick = async () => {
    try {
      const response = await axios.delete(`/transactions/${transactionId}/cancel`);
      history.push(`/transactions/${response.data._id}`, {
        flash: {
          success: {
            title: 'Storno erfolgreich!',
            body: 'Der Einkauf wurde erfolgreich storniert.'
          }
        }
      })
    } catch (e) {
      
    }
  }

  if (fetchTransaction !== 'idle') {
    return (
      <div className="container-fluid mb-4">
        <h2>TRANSAKTIONSDETAILS</h2>
        <div className="container">
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid mb-4">
      <Helmet>
        <title>Transaktionsdetails - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>TRANSAKTIONSDETAILS</h2>
      <div className="row">
        <h4 className="col-md-6 text-muted">{transaction.user.usernameDisplay} - { moment(transaction.createdAt).format('DD.MM.yyyy HH:mm') }</h4>
        <div className="col-md-6 text-right">
          {
            transaction.type !== 'cancellation' && transaction.referenceTransactions.length === 0 ?
              <button type="button" className="btn btn-outline-primary btn-sm" onClick={onCancelTransactionClick}>
                Stornieren
              </button> : null
          }
          {
            /*

            <button type="button" className="btn btn-outline-primary btn-sm" onClick={setEditMode.bind(null, !editMode)}>
            {
              editMode ? 'Fertig' : 'Bearbeiten'
            }
          </button>
             */
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="card">
            <div className="card-header">
              TRANSAKTIONSDETAILS
            </div>
            <div className="card-body">
              <div className="card-text">
                <div>
                  <div className="text-muted"><small>TRANSAKTIONS TYP</small></div>
                  <div>{transaction.type === 'cancellation' ? 'Storno' : 'Einkauf'}</div>
                </div>
                {
                  transaction.referenceTransactions.length > 0 ?
                    <div>
                      <div className="text-muted"><small>REFERENZIERTE TRANSAKTIONEN</small></div>
                      {
                        transaction.referenceTransactions.map((transaction) => (
                          <div key={transaction._id}>
                            <Link to={`/transactions/${transaction._id}`}>
                              { transaction.type === 'cancellation' ? 'Storno' : 'Transaktion' }
                            </Link>
                          </div>
                        ))
                      }
                    </div> : null
                }
                <div>
                  <div className="text-muted"><small>TERMINAL</small></div>
                  <div>{transaction.terminal.name}</div>
                </div>
                <div>
                  <div className="text-muted"><small>DATUM</small></div>
                  <div>{ moment(transaction.createdAt).format('DD.MM.yyyy') }</div>
                </div>
                <div>
                  <div className="text-muted"><small>UHRZEIT</small></div>
                  <div>{ moment(transaction.createdAt).format('HH:mm:ss') }</div>
                </div>
                <div>
                  <div className="text-muted"><small>BUCHUNGSBETRAG</small></div>
                  <div style={{fontSize: '2rem'}}><b>{ EuroFormatter.format(transaction.transactionTotal) }</b></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="card mt-2 mt-md-0">
            <div className="card-header">
              POSITIONEN
            </div>
            <div className="card-body">
              <div className="d-flex">
                <div style={{width: 80}}><b>Anzahl</b></div>
                <div className="flex-grow-1"><b>Artikel</b></div>
                <div><b>Preis</b></div>
              </div>
              {
                transaction.items.map((item, i) =>
                  <div key={`${item._id}${i}`} className="d-flex align-items-center" role="button" style={{height: 50}}>
                    <div style={{width: 80}}>{item.amount}</div>
                    <div className="flex-grow-1">
                      {
                        item.item.comment ?
                          <span>
                            {item.item.name} <br />
                            <small className="text-muted">{item.item.comment}</small>
                          </span> :
                          <span>
                            {item.item.name}
                          </span>
                      }
                    </div>
                    <div><b>{EuroFormatter.format(
                      transaction.type === 'cancellation' && item.item.deposit ? (item.item.price - item.item.deposit.price) * item.amount :
                        item.item.deposit ? (item.item.price + item.item.deposit.price) * item.amount :
                        (item.item.price * item.amount)
                    )}</b></div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionDetailPage;
