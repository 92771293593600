import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function DeleteItemPage({ axios, eventName }) {
  const location = useLocation();
  const history = useHistory();

  const item = location.state.item;

  const onDeleteClick = async () => {
    try {
      const response = await axios.delete(`/items/${item._id}`);
      history.replace('/items');
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.message) {

      } else {
        // e.message
      }
    }
  }

  if (!item) {
    return <Redirect to={'/items'} />;
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Artikel löschen - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>ARTIKEL LÖSCHEN</h2>
      <h4 className="text-muted text-uppercase">{item.name}</h4>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="mb-3">
              Soll <b>{item.name}</b> wirklich gelöscht werden?
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button className="btn btn-secondary mx-2" type="button" onClick={() => {history.replace('/items');}}>Abbrechen</button>
              <button className="btn btn-danger" type="button" onClick={onDeleteClick}>Löschen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteItemPage;
