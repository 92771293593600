import SMSLogo from '../sms_logo.jpg';
import NikeWallpaper from '../nike_wallpaper.jpg';
import './LeaderBoardPage.scss';
import { useEffect, useState } from 'react';

function LeaderBoardPage( { axios } ) {
  const [ bierkoenig, setBierkoenig ] = useState([]);
  const [ schierkerKoening, setSchierkerKoening ] = useState([]);

  const getbeer = async () => {
    const response = await axios.get('/leaderboard/beer');
    setBierkoenig(response.data);
  }

  const getSchierker = async () => {
    const response = await axios.get('/leaderboard/schierker');
    setSchierkerKoening(response.data);
  }

  useEffect(() => {
    setInterval(() => {
      getbeer();
      getSchierker();
    }, 60000);
    getbeer();
    getSchierker();
  }, []);

  return (
    <div className="bg-white position-absolute h-100 w-100 pl-0 pt-0 overflow-hidden" style={{background: `url(${NikeWallpaper}) no-repeat center center fixed`, backgroundSize: 'cover'}}>
      <div className="container-fluid row pt-4">
        <div className="col-6">
          <h1 className="text-center text-white">BIERKÖNIG</h1>
          <div className="d-flex flex-column">
            {
              bierkoenig.slice(0,8).map((user) =>
                <div className="mt-2 p-2 leader-board-item d-flex align-items-center" key={user.user._id}>
                  <div className="flex-grow-1">{user.user.usernameDisplay}</div>
                  <div className="text-right">{user.sum}</div>
                </div>
              )
            }
          </div>
        </div>
        <div className="col-6">
          <h1 className="text-center text-white">SCHIERKERKÖNIG</h1>
          <div className="d-flex flex-column">
            {
              schierkerKoening.slice(0,8).map((user) =>
                <div className="mt-2 p-2 leader-board-item d-flex align-items-center" key={user.user._id}>
                  <div className="flex-grow-1">{user.user.usernameDisplay}</div>
                  <div className="text-right">{user.sum}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderBoardPage;
