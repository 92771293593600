import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as $ from 'jquery';
import EuroFormatter from '../EuroFormatter.utils';

function DepositCategoryListPage({ axios }) {
  const [ deposits, setDeposits ] = useState([]);
  const [ depositsFetch, setDepositsFetch ] = useState('pending');

  const history = useHistory();

  const onEditDepositCategoryClick = (depositCategory) => {
    $('#modalEditDepositCategory').modal('show');
  };

  useEffect(() => {
    const fetchDeposits = async () => {
      setDepositsFetch('pending');
      const deposits = await axios.get('/deposits');
      setDeposits(deposits.data.map((deposit) => ({...deposit, value: EuroFormatter.format(deposit.price)})));
      setDepositsFetch('idle');
    }

    fetchDeposits();
  }, [])

  return (
    <div className="container-fluid">
      <h2>PFANDKATEGORIEN</h2>

      <div className="row">
        <div className="col-sm-4">
          <input className="form-control w-100" type="text" placeholder="Suche" />
        </div>
        <div className="col-sm-3 ml-auto text-right mt-1 mt-sm-0">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => { history.push('/deposits/new') }}>
            Kategorie anlegen
          </button>
        </div>
      </div>

      <table className="table table-hover mt-4">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Pfandwert</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        {
          depositsFetch !== 'idle' ?
          <tr>
            <th colSpan={3} style={{textAlign: 'center'}}>
              <div className="spinner-border mb-2" role="status" />
              <div>LADE DATEN</div>
            </th>
          </tr> :
          deposits.map((deposit) => (
            <tr key={deposit._id}>
              <th scope="row">{ deposit.name }</th>
              <td>{ deposit.value }</td>
              <td className="text-right">
                {
                  //<button className="btn btn-outline-secondary btn-sm mx-1" title="Bearbeiten" onClick={onEditDepositCategoryClick}>✎</button>
                }
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>

      <div className="modal fade" id="modalEditDepositCategory" data-backdrop="static" data-keyboard="false" tabIndex="-1"
           aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">PFANDKATEGORIE BEARBEITEN</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              ...
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
              <button type="button" className="btn btn-primary">Speichern</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositCategoryListPage;
