import React, { useState } from 'react';

function GuestDataForm({ userdata = {}, handleDataInput }) {

  const handleChange = (evt) => {
    evt.preventDefault();
    if (evt.target.name === 'usernameDisplay') {
      handleDataInput({
        ...userdata,
        [evt.target.name]: evt.target.value,
        username: evt.target.value.trim().toLowerCase().replaceAll(' ', '-')
      });
    } else {
      handleDataInput({
        ...userdata,
        [evt.target.name]: evt.target.value
      });
    }
  }

  const handleCheckboxChange = (evt) => {
    evt.preventDefault();
    handleDataInput({
      ...userdata,
      [evt.target.name]: evt.target.checked
    });
  }

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="inputUsernameDisplay">Username</label>
          <input type="text" className="form-control" id="inputUsernameDisplay" name="usernameDisplay"
                 value={ userdata.usernameDisplay } onChange={ handleChange }/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="inputInternalUsername">Interner Username</label>
          <input type="text" className="form-control" id="inputInternalUsername" disabled={ true } name="username"
                 value={ userdata.username }/>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="inputFirstName">Vorname</label>
          <input type="text" className="form-control" id="inputFirstName" name="firstName"
                 value={ userdata.firstName } onChange={handleChange}/>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="inputLastName">Nachname</label>
          <input type="text" className="form-control" id="inputLastName" name="lastName"
                 value={ userdata.lastName } onChange={handleChange}/>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="inputEmail">E-Mail</label>
        <input type="email" className="form-control" id="inputEmail" name="email" value={ userdata.email } onChange={handleChange}/>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="selectAccountType">Konto-Typ</label>
          <select className="form-control" id="selectAccountType" name="accountType" onChange={handleChange} value={userdata.accountType}>
            <option value="prepaid">Prepaid</option>
            <option value="postpaid">Postpaid</option>
          </select>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="selectUserType">User-Typ</label>
          <select className="form-control" id="selectUserType" name="userType" onChange={handleChange} value={userdata.userType}>
            <option value="guest">Gast</option>
            <option value="team">Team</option>
            <option value="admin">Admin</option>
          </select>
          {
            userdata.userType === 'admin' ? (
              <small id="selectUserTypeHelpBlock" className="form-text text-muted">
                Das System sendet dem User eine Einladung per E-Mail mit der er sein Passwort zum Zugriff auf das Admin Panel setzen kann.
              </small>
            ) : null
          }
        </div>
      </div>
      <div className="form-group">
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="createAccountStatementTokenSwitch" name="createValueURL" checked={userdata.createValueURL} onChange={handleCheckboxChange}/>
          <label className="custom-control-label" htmlFor="createAccountStatementTokenSwitch">Link zur Umsatzabfrage generieren</label>
        </div>
      </div>
      <div className="form-group">
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id="sendAccountStatementTokenEmailSwitch" name="sendEmail" checked={userdata.sendEmail} onChange={ handleCheckboxChange }/>
          <label className="custom-control-label" htmlFor="sendAccountStatementTokenEmailSwitch">E-Mail mit Infos zur Umsatzabfrage versenden</label>
        </div>
      </div>
    </form>
  );
}

export default GuestDataForm;
