import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import ProcessingOverlay from '../ProcessingOverlay/ProcessingOverlay';
import { Helmet } from 'react-helmet';

function TerminalDetailsPage({ axios, eventName }) {
  const [ terminal, setTerminal ] = useState({});
  const [ terminalFetch, setTerminalFetch ] = useState('pending');

  const history = useHistory();
  const { tid } = useParams();

  const fetchTerminal = async () => {
    setTerminalFetch('pending');
    const response = await axios.get(`/terminals/${tid}`);
    setTerminal(response.data);
    setTerminalFetch('idle');
  }

  useEffect(() => {
    fetchTerminal();
  }, []);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Terminal Details - { eventName } | CLE WEB</title>
      </Helmet>
      <h2 className="text-uppercase">TERMINAL INFORMATIONEN</h2>
      <h4 className="text-muted text-uppercase">{terminal.name}</h4>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                TERMINAL INFORMATIONEN
              </div>
              <div className="card-body">
                <div>
                  <div className="text-muted"><small>NAME</small></div>
                  <div>{terminal.name}</div>
                </div>
                <div>
                  <div className="text-muted"><small>STANDORT</small></div>
                  <div>{terminal.location || 'kein Stanort gesetzt'}</div>
                </div>
                <div>
                  <div className="text-muted"><small>API TOKEN</small></div>
                  <div className="text-monospace">{terminal.apiToken}</div>
                </div>
                <div>
                  <div className="text-muted"><small>TAGS</small></div>
                  <div>
                    <div className="badge bg-primary text-light">{terminal.tag}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                QR CODE
              </div>
              <div className="card-body text-center">
                <div className="mb-4">
                  Mit diesem QR-Code können Terminal-Clients einfach angebunden werden
                </div>
                {
                  terminalFetch === 'idle' ?
                    <QRCode value={`${terminal._id};${terminal.apiToken};localhost:3005;${Date.now() + 3.6e6}`} /> :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        terminalFetch !== 'idle' ?
          <ProcessingOverlay text="Lade Terminaldaten" /> : null
      }
    </div>
  );
}

export default TerminalDetailsPage;
