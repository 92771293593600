import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import EuroFormatter from '../EuroFormatter.utils';
import { Helmet } from 'react-helmet';

function NewGuestTransactionPage({ axios, eventName }) {
  const { username } = useParams();
  const [ items, setItems ] = useState([]);
  const [ deposits, setDeposits ] = useState([]);
  const [ selectOptions, setSelectOptions ] = useState([]);

  const [ pageError, setPageError ] = useState(null);

  const [ order, setOrder ] = useState({
    0: {
      item: null,
      amount: 1
    }
  });

  const history = useHistory();

  useEffect(() => {
    const fetchItems = async () => {
      const response = await axios.get('/items?visibility=1');
      setItems(response.data);
      return response.data;
    }

    const fetchDeposits = async () => {
      const response = await axios.get('/deposits');
      setDeposits(response.data);
      return response.data;
    }

    const fetchData = async () => {
      const [items, deposits] = await Promise.allSettled([
        fetchItems(),
        fetchDeposits()
      ]);

      const groupedItems = items.value.reduce((previousValue, currentValue) => {
        if (previousValue[currentValue.category.name]) {
          return {
            ...previousValue,
            [currentValue.category.name]: [
              ...previousValue[currentValue.category.name],
              { value: currentValue, label: currentValue.name }
            ]
          }
        }

        return {
          ...previousValue,
          [currentValue.category.name]: [
            { value: currentValue, label: currentValue.name }
          ]
        }
      }, {});

      setSelectOptions(
        Object.keys(groupedItems).map((groupName) => ({
          label: groupName,
          options: groupedItems[groupName]
        }))
      );

    }

    fetchData();
  }, []);

  const handleSelectChange = (line, value) => {
    setOrder({
      ...order,
      [line]: {
        ...order[line],
        item: value
      }
    })
  }

  const handleAmountChange = (line, evt) => {
    setOrder({
      ...order,
      [line]: {
        ...order[line],
        amount: evt.target.value
      }
    });
  }

  const onPostClick = async () => {
    const orderItems = Object.values(order).filter((orderLine) => orderLine.item && orderLine.amount > 0);
    const orderParsed = orderItems.reduce((previousValue, currentValue) => {
      return [
        ...previousValue,
        {
          amount: currentValue.amount,
          item: {
            itemId: currentValue.item.value._id,
            // price: currentValue.item.value.price // OPT (only variable)
          }
        }
      ]
    }, []);

    try {
      const response = await axios.post(`/transactions?uname=${username}`, {
        items: orderParsed
      });

      history.replace({
        pathname: `/guests/${username}`
      });
    } catch (e) {
      if (e.isAxiosError && e.response && e.response.data.message) {
        setPageError(e.response.data.message);
      } else {
        setPageError(e.message);
      }
    }
  }

  const addLineButtonPress = () => {
    setOrder({
      ...order,
      [Object.keys(order).length]: {
        item: null,
        amount: 1
      }
    });
  };

  const deleteLinePress = (lineNo) => {
    setOrder(
      Object.values(order)
        .filter((order, i) => i !== lineNo)
        .reduce((previousValue, currentValue, currentIndex) => ({
          ...previousValue,
          [currentIndex]: currentValue
        }), {})
    );
  };

  if (items.length === 0) {
    return (
      <div className="container-fluid">
        Loading ...
      </div>
    );
  }

  const orderSum = Object.values(order)
    .reduce((previousValue, currentValue) => {
      if (!currentValue.item) {
        return previousValue;
      }

      /*if (currentValue.item.type === 'deposit') {
        return previousValue - currentValue.item.value.value * currentValue.amount;
      }*/

      if (currentValue.item.value.deposit) {
        return previousValue + ( currentValue.item.value.price + currentValue.item.value.deposit.price ) * currentValue.amount;
      }

      return previousValue + currentValue.item.value.price * currentValue.amount;
    }, 0);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>Neue Transaktion - { eventName } | CLE WEB</title>
      </Helmet>
      <h2>NEUE TRANSAKTION</h2>
      <h4 className="text-muted text-uppercase">{username}</h4>
      <div className="container">
        {
          pageError ?
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
              <strong>Bei der Transaktion ist ein Fehler aufgetreten!</strong><br /><span>{ pageError }</span>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            : null
        }
        <div className="card">
          <div className="card-body">
            <table className="table table-borderless mb-3">
              <thead>
              <tr>
                <th scope="col" style={{minWidth: 20}}>Position</th>
                <th scope="col" style={{minWidth: 50}}>Anzahl</th>
                <th scope="col" style={{minWidth: 200, width: 100}}>Artikel</th>
                <th scope="col" style={{minWidth: 30}}>Einzelpreis</th>
                <th scope="col" style={{minWidth: 30}}>Pfand</th>
                <th scope="col" style={{minWidth: 30}}>Gesamtpreis</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              {
                Object.values(order).map((orderLine, i) =>
                  <tr key={i}>
                    <th scope="col">
                      { i + 1 }
                    </th>
                    <td>
                      <input className="form-control" type="number" min="1" step="1" value={orderLine.amount} onChange={handleAmountChange.bind(null, i)} />
                    </td>
                    <td>
                      <Select options={selectOptions} onChange={handleSelectChange.bind(null, i)} value={orderLine.item} />
                    </td>
                    {
                      orderLine.item && orderLine.item.type === 'deposit' ?
                        <>
                          <td>
                            <input className="form-control" type="text" value="0,00 €" readOnly />
                          </td>
                          <td>
                            <input className="form-control" type="text" value={EuroFormatter.format(orderLine.item.value.value * (-1))} readOnly />
                          </td>
                          <td>
                            <input className="form-control" type="text" value={EuroFormatter.format( orderLine.item.value.value * (-1) * orderLine.amount)} readOnly />
                          </td>
                        </> :
                        <>
                          <td>
                            <input className="form-control" type="text" value={orderLine.item ? EuroFormatter.format(orderLine.item.value.price) : ''} readOnly />
                          </td>
                          <td>
                            <input className="form-control" type="text" value={orderLine.item && orderLine.item.value.deposit ? EuroFormatter.format(orderLine.item.value.deposit.price) : ''} readOnly />
                          </td>
                          <td>
                            <input className="form-control" type="text" value={orderLine.item ? orderLine.item.value.deposit ? EuroFormatter.format((orderLine.item.value.price + orderLine.item.value.deposit.price) * orderLine.amount) : EuroFormatter.format(orderLine.item.value.price * orderLine.amount)  : ''} readOnly />
                          </td>
                        </>
                    }

                    <td>
                      {
                        i > 0 ?
                          <button className="btn btn-outline-secondary btn-sm" onClick={deleteLinePress.bind(null, i)}>X</button> :
                          null
                      }
                    </td>
                  </tr>
                )
              }
              <tr>
                <td colSpan="7" className="text-center">
                  <button className="btn btn-outline-primary" role="button" onClick={addLineButtonPress}>Zeile hinzufügen</button>
                </td>
              </tr>
              <tr>
                <th scope="col" colSpan="2">{Object.keys(order).length} Pos.</th>
                <td colSpan="2"></td>
                <td className="text-right"><strong>SUMME</strong></td>
                <td className="text-right"><strong>{EuroFormatter.format(orderSum)}</strong></td>
              </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <button className="btn btn-secondary" role="button" onClick={history.goBack}>Abbrechen</button>
              <button className="btn btn-primary" role="button" onClick={onPostClick}>Buchen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewGuestTransactionPage;
